/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

:root {
    --icosaedro-header-bg-color: #039be5;
    --icosaedro-edit-color: #43a047;
    --icosaedro-delete-color: #f44336;
    --icosaedro-special-color: #607d8b;
}

.component-container,
.view-container {
    @apply absolute inset-0 flex flex-col min-w-0 overflow-hidden;
}

.header {
    @apply grid grid-cols-2 md:flex items-center sm:justify-between py-4 px-4 md:px-10 border-b bg-card dark:bg-transparent text-white;
    background-color: var(--fuse-primary);

    .title {
        @apply flex flex-row gap-4;

        grid-column: 1/2;
        grid-row: 1;

        .page-icon {
            @apply text-white items-center justify-center;
            height: 36px;
            width: 36px;
            font-size: 2.50rem;
        }

        .title-text {
            @apply text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate pt-1 sm:pt-0;
        }
    }

    .title-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
    }

    .generic-search {
        @apply w-full md:w-1/2 mt-2 md:-mt-2;

        grid-column: 1 / span 2;
        grid-row: 2;
        position: relative;
        height: 40px;

        generic-search {
            @apply flex flex-row w-full;
        }

        .mat-expanded {
            .generic-search-input-container {
                border-bottom-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity));
                border-bottom-width: 1px;
            }
        }

        .mat-expansion-panel {
            @apply mt-1.5 md:mt-0;
            overflow: hidden;
            z-index: 47;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            max-height: 80vh;
        }

        .mat-expansion-panel-content {
            max-height: 70vh;
            overflow-y: scroll;

            @media screen and (max-height: 800px) {
                max-height: 60vh;
            }

            @media screen and (max-height: 680px) {
                max-height: 50vh;
            }
        }

        .mat-expansion-panel-body {
            @apply mt-1.5
        }
    }

    .generic-search-form {
        @apply flex flex-col;

        .generic-search-filter {
            @apply flex flex-row;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .generic-search-toolbar {
            @apply flex flex-row justify-center items-center mt-4 gap-2;
        }
    }

    .actions-toolbar {
        @apply flex flex-row flex-shrink-0 items-center mt-0 sm:ml-4 justify-end;

        grid-column: 2/2;
        grid-row: 1;
    }

    .mat-expansion-panel-header {
        @apply h-10 md:h-12
    }
}

.main {
    @apply flex flex-col h-full w-full overflow-auto;
    position: relative;

    &.padded {
        @apply p-2 xs:p-4 sm:p-6 md:p-8;
    }

    .mat-mdc-header-cell {
        font-size: 16px;
        font-weight: 800;
    }

    .empty-table {
        @apply flex items-center justify-center h-16 text-xl;
    }

    .mat-mdc-paginator {
        display: flex;
        justify-content: center;
    }

    .custom-card {
        @apply w-full shadow md:rounded-lg bg-white p-4 mt-0 md:mt-4;
    }

    .ico-table {
        @apply w-full overflow-auto;

        .mat-mdc-header-cell {
            border-bottom-color: rgba(241, 245, 249, 0.12);
            border-bottom-width: 6px;
        }
    }

    .mat-horizontal-content-container {
        height: 100%;

        .mat-horizontal-stepper-content {
            overflow-y: auto;
            max-height: 100%;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field.mat-form-field-appearance-outline
    .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-prefix
    > .mat-icon {
        @apply mr-3;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-outline {
        background-color: white;
        border-radius: 0.5em;
    }

    .bottom-toolbar {
        @apply flex items-center justify-end px-6 py-3 space-x-3;
        //margin-top: 20px;
    }

    .form-grid {
        @apply grid gap-x-6 gap-y-4 grid-cols-1;
    }

    .column-truncate {
        @apply md:w-80;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .th-refresh-button {
        min-width: 120px;
    }

    .table-refresh-button {
        @apply top-0 right-1 h-full flex flex-col justify-center absolute;
    }

    .table-add-button {
        @apply top-4 right-5 h-full inline-block justify-center absolute;
    }

    .size-flag {
        height: 2rem;
        width: 2.5rem;
    }

    .cropped-image-btn-delete {
        label {
            height: 30px;
        }
    }

    .custom-disabled .mat-icon {
        opacity: 0.38 !important;
        color: rgba(var(--fuse-text-disabled-rgb), var(--tw-text-opacity)) !important;
    }

    .status-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;
    }
}

.actions-column-toolbar {
    .detail-button .mat-icon {
        color: var(--fuse-primary);
    }

    .edit-button .mat-icon {
        color: var(--icosaedro-edit-color);
    }

    .delete-button .mat-icon {
        color: var(--icosaedro-delete-color);
    }

    .special-button .mat-icon {
        color: var(--icosaedro-special-color);
    }
}

.flat-dialog-container {
    .mat-mdc-dialog-container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        border-radius: 0 !important;
        overflow-x: hidden;

        .mat-mdc-dialog-title {
            @apply flex justify-center items-center px-4 h-16;
            background-color: var(--fuse-primary);
            color: rgba(255, 255, 255, var(--tw-text-opacity));

            .title-text {
                @apply flex-grow ml-1;
            }

            .mat-icon {
                color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
            }
        }

        .mat-mdc-dialog-content {
            @apply flex flex-col w-full px-4;
            margin: auto;
        }

        .mat-mdc-dialog-actions {
            @apply flex items-center justify-end px-6 py-3 space-x-3;
            margin-top: 20px;
        }
    }
}

.icon-select-option {
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .mat-option-text {
        @apply flex gap-x-4 items-center;

        img {
            @apply h-8 w-10;
        }
    }
}

.img-lang-table {
    @apply h-8 w-10;
}

body {
    &.light {
        .ico-table {
            .mat-mdc-header-cell {
                border-bottom-color: rgb(226, 232, 240);
                border-bottom-width: 6px;
            }
        }

        .mat-stepper-horizontal,
        body.light .mat-stepper-vertical,
        body .light .mat-stepper-horizontal,
        body .light .mat-stepper-vertical {
            background-color: #f1f5f9;
        }
    }

    &.dark {
        .header {
            background-color: var(--fuse-primary);
        }

        .custom-card {
            background-color: rgb(30, 41, 59);
        }

        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
        .mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
            color: rgba(var(--fuse-primary-500-rgb), var(--tw-text-opacity)) !important;
        }

        .ico-table {
            .mat-mdc-header-cell {
                border-bottom-color: rgba(241, 245, 249, 0.12);
                border-bottom-width: 6px;
            }
        }
    }

    //Class of Lights
    .displaySensorLight {
        display: flex;
        padding: 7px;
    }

    .led-button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        bottom: 6px;
    }

    .led-grey {
        margin: 0 auto;
        width: 16px;
        height: 16px;
        background-color: #9e9e9e;
        border-radius: 50%;
    }

    .led-yellow {
        margin: 0 auto;
        width: 15px;
        height: 15px;
        background-color: #ffc107;
        border-radius: 50%;
    }

    .led-green {
        margin: 0 auto;
        width: 16px;
        height: 16px;
        background-color: #4caf50;
        border-radius: 50%;
    }

    .led-red {
        margin: 0 auto;
        width: 16px;
        height: 16px;
        background-color: #fd3838;
        border-radius: 50%;
    }

    .led-box {
        height: 16px;
        width: 10%;
        margin: 10px;
    }
}

.dark {
    fuse-vertical-navigation-basic-item
    > .fuse-vertical-navigation-item-wrapper
    .fuse-vertical-navigation-item:not(
            .fuse-vertical-navigation-item-disabled
        ).fuse-vertical-navigation-item-active {
        border-radius: 0 20px 20px 0;
        background-color: var(--fuse-primary) !important;
        font-weight: bolder;
    }
}

/* PrimeNG */
.p-tree {
    color: var(--fuse-text-default);
    --tw-text-opacity: 1 !important;
    border: 1px solid rgb(203 213 225 / var(--tw-text-opacity)) !important;
}

.p-tree:hover {
    transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--fuse-primary);
    background-color: var(--fuse-primary);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: var(--fuse-primary-100);
    color: var(--fuse-text-default);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: inset 0 0 0 0.15rem var(--fuse-primary-200);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--fuse-primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--fuse-primary-400);
    background: var(--fuse-primary-400);
    color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-datatable .p-sortable-column:focus {
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-100);
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: var(--fuse-primary-600);
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: var(--fuse-primary-700);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--fuse-primary-600);
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--fuse-primary-700);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--fuse-primary-100);
    border-color: var(--fuse-primary-100);
    color: var(--fuse-text-default);
}

.p-link:focus {
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
}

.p-dropdown:not(.p-disabled):hover {
    border-color: var(--fuse-primary-400);
}

.p-accordion .p-accordion-content {
    padding: 2rem;
}

.sticky-head {
    .p-datatable-wrapper {
        overflow: initial !important;
    }

    .p-datatable-thead {
        th {
            position: sticky;
            top: 0;
            z-index: 10 !important;
        }
    }
}

.sticky-head-in-modal {
    .p-datatable-wrapper {
        overflow-x: initial !important;
    }

    .p-datatable-thead {
        th {
            position: sticky;
            top: 0;
            z-index: 10 !important;
        }
    }
}

.sticky-footer {
    .p-datatable .p-paginator {
        position: sticky;
        bottom: 0;
        z-index: 10 !important;
    }
}

body {
    &.dark {
        .p-tree {
            background-color: rgb(30, 41, 59);
            --tw-text-opacity: 1 !important;
            border: 1px solid rgb(100 116 139 / var(--tw-text-opacity)) !important;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
            color: #495057;
        }
    }
}

/* rotate refresh button */
.rotate-refresh-button {
    animation: rotation 2s infinite linear;
}

mat-checkbox input[disabled] {
    opacity: 0;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 0 !important;
    padding: 0;
}

.mat-mdc-dialog-title h2.title-text {
    font-size: revert;
    font-weight: 600;
}

.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 !important;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
